body {
  font-size: 14px;
}

main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

.bg-zebra {
  background-color: #bca774 !important;
  border-color: #bca774 !important;
}
.border-zebra {
  border-color: #bca774 !important;
}

.btn-zebra {
  color: #000;
  background-color: #bca774;
  border-color: #bca774;
}

.btn-zebra:hover {
  color: #000;
  background-color: #bca774;
  border-color: #bca774;
}

.btn-outline-zebra {
  color: #bca774 !important;
  border-color: #bca774 !important;
}
.btn-outline-zebra:hover {
  color: #000 !important;
  background-color: #bca774 !important;
  border-color: #bca774 !important;
}
.btn-print {
  padding: 4px 8px;
  background-color: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* .btn-zebra-dar {
  color: #000;
  background-color: #796e54;
  border-color: #796e54;
}
.btn-zebra-dar:active {
  color: #000;
  background-color: blue;
  border-color: blue;
} */
/* .btn-zebra-dar:hover {
  color: #000;
  background-color: red;
  border-color: red;
} */

/* .btn-zebra-dar:selected {
  color: #000;
  background-color:yellow;
  border-color:yellow;
} */
.nav-link {
  color: #bca774;
}
.textZebra {
  color: #bca774;
}

.nav-link:focus,
.nav-link:hover {
  color: #a58c52;
}

#footer {
  /*position: fixed;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*z-index: 1030;*/
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 1030;
}

#header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1030;
}

.accredited-logo {
  width: 90px;
  height: 90px;
  /*margin-right: 4px;*/
}

.zebra-logo {
  /*width: 210px;*/
  /*height: 60px;*/
  width: 225px;
  height: 45px;
}

.tbody-td {
  padding-left: 15px;
}

.btn-primary {
  color: #fff;
  background-color: #bca774;
  border-color: #bca774;
}
.btn-primary:hover {
  color: #000;
  background-color: #ceb887;
  border-color: #bca774;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ceb887;
  border-color: #ceb887;
}

/*@media screen and (min-width: 512px) and (max-width: 767px), print and (orientation: portrait) {*/
/*  .accredited-logo {*/
/*    width: 60px;*/
/*    height: 60px;*/
/*    margin-right: 3px;*/
/*  }*/

/*  .zebra-logo {*/
/*    width: 140px;*/
/*    height: 40px;*/
/*  }*/
/*}*/

/*@media screen and (min-width: 375px) and (max-width: 511px), print and (orientation: portrait) {*/
/*  .accredited-logo {*/
/*    width: 45px;*/
/*    height: 45px;*/
/*    margin-right: 2px;*/
/*  }*/

/*  .zebra-logo {*/
/*    width: 105px;*/
/*    height: 30px;*/
/*  }*/
/*}*/

/*@media screen and (max-width: 374px), print and (orientation: portrait) {*/
@media screen and (max-width: 374px), print and (orientation: portrait) {
  .accredited-logo {
    width: 60px;
    height: 60px;
    /*margin-right: 1px;*/
  }

  .zebra-logo {
    width: 150px;
    height: 30px;
  }
}

/*@media screen and (max-width: 1500px), print and (orientation: portrait) {*/
/*  #footer {*/
/*    position: relative;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    z-index: 1030;*/
/*  }*/
/*  #header {*/
/*    position: relative;*/
/*    right: 0;*/
/*    top: 0;*/
/*    left: 0;*/
/*    z-index: 1030;*/
/*  }*/
/*  !* #header {*/
/*    font-size: 13px !important;*/
/*  } *!*/
/*}*/

@media only screen and (max-width: 600px) {
  body {
    font-size: 12px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-arrows[type=number] {
  -moz-appearance: textfield;
}
